<template>
  <v-chip class="bsa-chip">
    <slot />
  </v-chip>
</template>

<style>
.bsa-chip.v-chip--variant-tonal {
  background-color: rgb(var(--v-theme-tag-background));
  color: rgb(var(--v-theme-tag-text));
}
</style>
