
import '@/styles/fonts.scss';

import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue'
import { useTheme } from 'vuetify/lib/framework.mjs';

import { useSnackbarStore } from './store/snackbar';

export default defineComponent({
  name: 'App',
  computed: {
    ...mapWritableState(useSnackbarStore, ['active']),
    ...mapState(useSnackbarStore, ['message']),
    background() {
      const theme = useTheme();
      if (theme.global.current.value.dark) {
        return 'background: linear-gradient(244.69deg, #242424 39.37%, rgba(5, 5, 5, 0.85) 95.97%)';
      }
      return 'background: #FAFAFA';
    }
  },
})
